import React from 'react'

const Button = ({ text, style, color, handleClick, icon, iconAlt }) => {
  const textColor = color ? `text-${color}` : ""
  const padding = icon ? `pl-2 pr-3` : `px-3`

  return (
    <button onClick={handleClick} className={`border font-bold font-sans h-10 ${padding} rounded-lg text-xs ${textColor} tracking-wider uppercase`}>
      <div className="flex items-center">
        {!!icon &&
          <img src={icon} alt={iconAlt} className="pr-2" />
        }
        {text}
      </div>
    </button>
  )
}

export default Button
