import React, { useState } from 'react'
import { Link } from 'gatsby'

import ShuffleIcon from '../../icons/shuffle.svg'
import Lib from './lib'
import Button from '../button'
import { getRandomLib, getRandomLibs } from './mad-lib-utils'

const MadLibs = () => {
  const [libs, setLibs] = useState(getRandomLibs())

  const handleIndividualShuffle = (section) => {
    setLibs({...libs, [section]: getRandomLib(section)})
  }

  const handleAllShuffle = () => {
    setLibs(getRandomLibs())
  }

  const renderLib = (section) => (
    <Lib
      section={section}
      text={libs[section]}
      handleShuffle={handleIndividualShuffle}
    />
  )

  return (
    <div className="mad-libs flex flex-col font-serif-2 justify-between min-h-1/2 text-3xl lg:text-4xl text-theme2-primary">
      <div className="mb-8">
        {renderLib('precursor')} Ruiz & Knott is the {renderLib('entity')} of
        Ivan and Olivia Ruiz-Knott{!!libs['aka'] && renderLib('aka')}, {renderLib('couple')}
        who {renderLib('phrase')} {renderLib('currentlyOrSomeday')}
      </div>
      <div className="flex items-center">
        <Button
          text="Shuffle All"
          color="theme2-accent"
          icon={ShuffleIcon}
          iconAlt={"shuffle"}
          handleClick={handleAllShuffle}
        />
        <div className="font-bold font-sans ml-4 text-theme2-accent text-xs tracking-wider uppercase">
          <Link to="/about">Learn More →</Link>
        </div>
      </div>
    </div>
  )
}

export default MadLibs
