export const MAD_LIBS_LIST = {
  precursor: [
    "Founded in <a href='since'>2014</a>",
    "Eight <a href='since'>years</a> strong",
    "Forged in the frigid winters of Southwest Michigan",
    "Steeped in the humid summers of Southwest Michigan",
    "Incubated in an anomalous utopia",
    "A mysterious inevitability",
    "Currently based in Denver",
    "Founded in Berrien Springs, Michigan",
    "Developed in part through an epistolary friendship",
    "Hailing from the greater Berrien metropolitan area",
    "A woman- and minority-owned company",
    "Hailing from a pleasant peninsula",
    "Hailing from the Middle West",
  ],
  entity: [
    // 'fashion house',
    // 'design studio/two-bedroom/house/condo',
    // 'research and development firm',
    // 'company',
    // 'pretend corporation',
    // 'studio',
    // 'coterie',
    // 'design practice',
    // 'creative practice',
    // 'creative industrial complex',
    "personal brand",
    "nap studio",
    "marriage",
    "holy union",
    "friendship",
    "~studio~ two-bedroom",
    "detective agency",
    "underground crime syndicate",
    "think tank",
    "digital home",
    "design and development playground",
    "game design studio",
    "skunkworks lab",
    "nom de guerre",
    "copywriting agency",
    "corporation",
    "outfit",
    "tactical strike team",
    "task force",
    "portfolio site",
    "very serious creative practice of",
    "exclusive partnership",
    "skeleton crew",
    "merger",
    "league",
    "creative alliance",
    "personal association",
    "cooperative",
    "dog appreciation society",
    "two-player game",
    "art and design practice",
    "avocado holding company",
  ],
  aka: [
    "also known as V",
    "known informally as Ivan and Livvy",
    "known to some as “the two of you”",
    "sometimes referred to as Livvan and Ivy",
    "sometimes referred to as Livan and Ivvy",
    "known to some as Vinny and Livvy",
    "also known as the RKs",
    "also known as “you meddling kids”",
  ],
  couple: [
    // 'future fabulists',
    // 'amateur sandwich makers',
    "a married couple",
    "an obvious couple",
    "an affectionate couple",
    "a couple of best friends",
    "a manic-pixie dream couple",
    "a ludic couple",
    "two kids in a trench coat",
    "a couple of spring chickens",
    "people people",
    "bridge-y people",
    "a creative couple",
    "a thievishly thick duo",
    "future mom-and-pop shopkeepers",
    "a renaissance couple",
  ],
  phrase: [
    // 'make apps',
    // 'have really good communication skills',
    // 'create tools to create art to communicate meaning',
    // 'tinker',
    // 'host serious events',
    // 'make sounds',
    "go way back",
    'keep <a href="/projects/guestbook-data-projects/">really detailed records</a>',
    "are pursuing high quality lives",
    "occasionally write in third person",
    "make really good sandwiches",
    "are really indoorsy",
    "have a bus factor of 1",
    "thoroughly dislike sparkling water",
    "punch above their weight",
    "make stuff up",
    "are not as problematic as they used to be",
    "buckle swashes",
    "run a very unpopular skateboarding gang",
    "come up with ideas",
    "don’t specialize",
    "fold mysteries",
    'are eagerly waiting for the <a href="https://css-tricks.com/leading-trim-the-future-of-digital-typesetting/" target="_blank" rel="noopener noreferrer">leading-trim spec</a> to drop',
    "think about community",
    "were raised Seventh-day Adventist",
    "have been drastically misrepresented in the press",
    "play board games",
    "are trying to be sneakier",
    "make friends",
    "start a lot of things",
    "dabble extensively",
    "host silly events",
    "work on serious side projects",
    "you’d never suspect",
    "work on silly side projects",
    "make plucky background music",
    "have trouble picking a description",
    "are deeply in love with each other",
    "have been friends since high school",
    "are working on the next small thing",
    "are in cahoots",
    "like making up rituals",
    "work at the intersection of Larimer and 16th",
    "are huge fans of their breakfast burritos",
    ' <a href="/projects/candidates-at-the-barclay/">met most of the Denver mayoral candidates</a>',
  ],
  currentlyOrSomeday: [
    // 'Currently, they are sleeping',
    // 'Currently, they are working',
    // 'Currently, they are on vacation',
    // 'Currently, they are thinking a lot about ...',
    // 'Currently, they host moon-based productivity celebrations',
    // 'Currently, they are plotting something',
    // 'Someday, they’re going to start a cult',
    // 'Currently, they are planning an end-of-summer picture show',
    // 'Currently, they are organizing a book report society',
    "Currently, they are up-to-date on the MCU",
    "Currently, they daylight as a designer and developer",
    'Currently, they are <a href="https://futureland.tv/liv_vy/tapestry/" target="_blank" rel="noopener noreferrer">weaving a social fabric</a>',
    "Currently, they host semi-contemplative dinners",
    "Currently, they’re planning their next PowerPoint party",
    "Currently, they are designing and building this website",
    "Currently, they are making a game for the Playdate",
    "Someday, they’re going to go to bed early",
    "Someday, they’re going to make a video game about Levitical law",
    "Someday, they’ll reluctantly come out of retirement to pull one last heist",
    'Someday, they hope to be as cool as <a href="https://100r.co/" target="_blank" rel="noopener noreferrer">Hundred Rabbits</a>',
    'Someday, they hope to be as cool as <a href="http://www.lullatone.com/" target="_blank" rel="noopener noreferrer">Lullatone</a>',
    'Someday, they hope to be as cool as <a href="https://rsms.me/" target="_blank" rel="noopener noreferrer">Rasmus</a>',
    'Someday, they hope to be as cool as <a href="https://cwandt.com/" target="_blank" rel="noopener noreferrer">CW&T</a>',
    'Someday, they hope to be as cool as <a href="https://dukope.com/" target="_blank" rel="noopener noreferrer">Lucas Pope</a>',
    'Someday, they hope to be as cool as <a href="https://malikakhurana.com/" target="_blank" rel="noopener noreferrer">Malika</a>',
    'Someday, they hope to be as cool as <a href="https://www.quartey.com/writing/designing-family-home/" target="_blank" rel="noopener noreferrer">Emmanuel Quartey</a>',
  ],
};

export const getRandomLib = (section) => {
  const madLibSection = MAD_LIBS_LIST[section];
  const randomLib =
    madLibSection[Math.floor(Math.random() * madLibSection.length)];
  return randomLib;
};

export const getRandomLibs = () => {
  return {
    precursor: getRandomLib("precursor"),
    entity: getRandomLib("entity"),
    // Only render the 'aka' section sometimes
    aka: Math.random() < 0.3 ? getRandomLib("aka") : null,
    couple: getRandomLib("couple"),
    phrase: getRandomLib("phrase"),
    currentlyOrSomeday: getRandomLib("currentlyOrSomeday"),
  };
};
