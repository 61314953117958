import React from 'react'
import { marked } from 'marked'

import ShuffleIcon from '../../icons/shuffle.svg'

const Lib = ({ section, text, handleShuffle }) => {
  const parsedText = { __html: marked.parseInline(text) }
  const textAsArray = parsedText.__html.split(' ')
  const first = { __html: textAsArray.shift() }
  const remaining = { __html:  " " + textAsArray.join(' ') }
  
  return (
    <span>
      {section === 'aka' ? ' ' : ''}
      <span className = "whitespace-nowrap">
        {section === 'aka' ? '(' : ''}
        <button onClick={() => { handleShuffle(section) }} className='align-middle mr-1 touch-manipulation'>
          <img src={ShuffleIcon} alt="shuffle" />
        </button>
        <span dangerouslySetInnerHTML={first}></span>
      </span>
      <span dangerouslySetInnerHTML={remaining}></span>
      {section === 'precursor' ? ',' : ''}
      {section === 'couple' ? ' ' : ''}
      {section === 'phrase' || section === 'currentlyOrSomeday' ? '.' : ''}
      {section === 'aka' ? ')' : ''}
    </span>
  )
}

export default Lib
