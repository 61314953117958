import React from 'react'
import { graphql, Link } from 'gatsby'

import Layout from '../components/layout'
import HelmetWrapper from '../components/helmet-wrapper'
import MadLibs from '../components/mad-libs/mad-libs'
import HeaderSans from '../components/headers/header-sans'
import ProjectListing from '../components/projects/project-listing'

const RootIndex = ({ location, data }) => {
  let madLibs = ""
  if (typeof window !== 'undefined') {
    localStorage.removeItem('origin')
    madLibs = <MadLibs />
  }

  const {
    titleImage,
    projects
  } = data.allContentfulHomePage.edges[0].node

  return (
    <Layout location={location}>
      <HelmetWrapper pageTitle={"Home"} />
      <div className="bg-theme2-bg-1 md:p-4">
        <div className="md:flex md:justify-between md:py-10 md:max-w-5xl md:mx-auto">
          <div className="p-4 md:p-0 md:mr-8 md:w-1/2">
            {madLibs}
          </div>
          <div className="md:w-1/2">
            {titleImage &&
              <img alt="" src={titleImage.file.url} />
            }
          </div>
        </div>
      </div>
      <div className="p-4 md:py-12">
        <div className="md:max-w-5xl md:mx-auto">
          <HeaderSans text="Selected Projects" tag="h2" />
          <ProjectListing projects={projects} />
          <div className="font-bold font-sans mt-4 text-theme1-accent text-xs tracking-wider uppercase underline decoration-1 decoration-theme1-accent underline-offset-2">
            <Link to="/projects">All Projects →</Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    allContentfulHomePage {
      edges {
        node {
          titleImage {
            file {
              url
            }
          }
          titleImageDescription {
            childMarkdownRemark {
              html
            }
          }
          projects {
            id
            title
            slug
            description {
              description
            }
            when
          }
        }
      }
    }
  }
`
